@import url('https://fonts.googleapis.com/css?family=Roboto');

/*
ziko color schema
*/
input::-ms-clear {
    width: 0;
    height: 0;
}

input[type="date"]::-ms-clear {
    display: none;
}

.rdw-editor-main {
    cursor: text;
}

body {
    display: flex;
    flex-direction: column;
}

.mgt-25 {
    margin-top: 25px;
}

.mgt-50 {
    margin-top: 50px;
}

/* img{
pointer-events: none;
} */
input[disabled] {
    color: #777 !important;
    font-weight: bold;
}

.w-25 {
    width: 25%;
}

.free-type-question {
    margin-top: 25px;
}

.css-10nd86i,
.css-vj8t7z,
.css-1hwfws3,
.css-vj8t7z,
.css-2o5izw {
    /* height: 40px !important; */
    max-width: 89.5% !important;
}

.css-vj8t7z {
    border-radius: 4px !important;
}

.css-15k3avv {
    max-width: 89.5% !important;
}

.fix-btn-test {
    margin-bottom: 100px
}

.font-red {
    color: rgb(216, 38, 38) !important;
}

.ml-10 {
    margin-left: 10px;
}

.fix-add-answer {
    margin-bottom: 25px;
    margin-right: 0 !important;
}

/*
table custom style
*/
.ReactTable .rt-tr:hover {
    background: rgba(204, 204, 204, 0.384);
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-right: 0 !important;
}

.ReactTable .rt-thead {
    background: #2f2f2f;
    color: #fff;
    /* padding: 10px 0; */
}

.pagination-bottom {
    display: none;
}

.ReactTable .rt-tbody .rt-td {
    text-align: center;
    padding: 15px 0;
    border-right: 0 !important;
}

.ReactTable .rt-thead.-header {
    box-shadow: none !important
}

.ReactTable,
.ReactTable .rt-table {
    border-radius: 5px;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
    height: 50px;
    line-height: 50px;
}

/****************/
.pop-h {
    position: absolute;
    top: 60px;
    right: 10px;
}

.input-checkbox {
    display: inline;
    margin-right: 20px;
    width: 20px;
    height: 20px;
}

.permitted-title {
    margin: 25px 0;
    color: #0F485C;
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
}

.permitted-row {
    height: 25px;
    line-height: 25px;
}

.permitted-row:hover {
    background: rgba(204, 204, 204, 0.1);
}

.coub-send-award {
    border: 2px solid #d1ab66;
    color: #2f2f2f;
    background: #fff;
}

.min-block-right .coub-start {
    background: #d1ac6679 !important;
    color: #2f2f2f !important;
    border: 2px solid #d1ab66;
    font-weight: bold;
}

.fix-table-font {
    font-size: 12px;
}

.href {
    color: #d1ab66 !important;
    cursor: pointer;
}

.relative {
    position: relative;
}

.selector-input {
    position: relative;
    background: #fff;
}

.add-file-chat {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 18px;
}

.permitted-block {
    width: 100%;
    margin: 10px 0;
    animation: permitted .3s forwards;
    transform: scale(0);
    margin-bottom: 25px;
}

@keyframes permitted {
    100% {
        transform: scale(1)
    }
}

.selector-input .window-selector {
    position: absolute;
    width: 100%;
    top: -20px;
    left: 0;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, .2);
    background: #fff;
    padding: 0px 0;
    z-index: 9;
    overflow: hidden;
}

.hide {
    display: none;
}

.window-selector .window-selector-punct {
    padding: 10px;
    width: 100%;
    cursor: pointer;
}

.window-selector .window-selector-punct:hover {
    background: #045072;
    color: #fff;
}

.container-award {
    width: 100px;
    margin: 20px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

.container-award img {
    width: 80px;
}

.hidden {
    display: none;
}

.ddddd {
    color: #fff !important;
}

.dialog_big {
    width: 500px;
}

/* .ddddd svg{
    display: none;
} */
.b-0 {
    border: 0 !important;
}

.achievement-block {
    height: 200px;
    /* background: rgb(238, 238, 238); */
    width: 100%;
    /* border-bottom: 1px solid rgba(196, 196, 196, 0.3); */
}

.achievement-block .achievement-block-children {
    float: left;
    width: 10%;
    text-align: center;
    height: 120px;
    margin-top: 20px;
    position: relative;
}

.achievement-block .achievement-block-children-complete::before {
    content: '✔';
    color: #fff;
    font-size: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(61, 192, 61);
    position: absolute;
    right: 5%;
    bottom: 10%;
    z-index: 2;
}

.achievement-block .achievement-block-children img {
    max-width: 90%;
    max-height: 90%;
}

.achievement-block-full {
    /* background: rgb(238, 238, 238); */
    width: 100%;
    border-radius: 5px;
    margin-top: 15px;

    /* border-bottom: 1px solid rgba(196, 196, 196, 0.3); */
}

.achievement-block-full-children {
    position: relative;
    min-height: 150px;
    width: 95%;
    margin: 15px auto;
}

.achievement-block-full-children .achievement-block-desc {
    position: absolute;
    top: 0px;
    left: 10px;
    padding-top: 10px;
    max-width: 50%;
    text-align: left;
    height: 140px;
    margin: 5px;
    position: relative;
    padding-left: 10px;
}

.achievement-block-full-children .achievement-block-full-img {
    float: left;
    width: 140px;
    max-width: 20%;
    text-align: center;
    height: 140px;
    margin: 5px;
    position: relative;
}

.sort-icon {
    color: #fff;
    margin-right: 10px;
}

.achievement-block-full .achievement-block-children-complete::before {
    content: '✔';
    color: #fff;
    font-size: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(61, 192, 61);
    position: absolute;
    right: 5%;
    bottom: 10%;
    z-index: 2;
}

.achievement-block-full-img img {
    width: 140px;
    height: 140px;
}

.fix-all-awards {
    position: relative;
    top: 5px;
}

.achievement-block .achievement-black img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.achievement-block h3 {
    margin: 5px 0;
}

.create-form {
    width: 50%;
    margin-top: 25px;
}

.test-education {
    height: 120px !important;
}

.test-education .education-cover {
    width: 160px !important;
}

.create-form .css-10nd86i,
.create-form .css-2o5izw,
.create-form .css-vj8t7z,
.create-form .css-15k3avv {
    min-width: 100%;
    height: auto !important;
}

.opacity-none {
    opacity: 0;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden;
    display: none !important;
}

.table-mdm,
.togglePanel {
    box-shadow: 0 0 10px rgba(0, 0, 0, .1) !important;
}

input[type="password"] {
    letter-spacing: 0.1px !important;
}

.form-input-line {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.loadMaterialBtn {
    width: 100%;
    background: transparent;
    color: #ccc;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    margin-top: 25px;
    border: 0;
    cursor: pointer;
    transition: background .3s, color .2s
}

.loadMaterialBtn:hover {
    color: rgb(175, 175, 175);
    background: rgb(247, 247, 247);
}

.loadMaterialProgress {
    width: 30px;
    height: 30px;
    margin: 10px auto;
    border-radius: 50%;
    border-left: 3px solid rgb(175, 175, 175);
    border-top: 3px solid rgb(175, 175, 175);
    border-bottom: 3px solid rgb(175, 175, 175);
    border-right: 3px solid transparent;
    animation: loadMaterialProgress .6s ease-out infinite;
}

@keyframes loadMaterialProgress {
    100% {
        transform: rotate(360deg)
    }
}

.btn-ui {
    background: #02AEFF;
    color: #fff;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 4px;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;
    position: absolute;
    bottom: -10px;
    left: 30px;
}

textarea {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.btn-ui:hover {
    background: rgb(0, 165, 241);
}

.tablehead-md {
    background: #045072;
    color: #fff !important;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.admin-menu {
    flex-basis: 400px;
    background: #F7F7F7;
}

.admin-menu i {
    color: #505050;
    /* margin-right: 10px; */
    width: 25px;
}

.admin-menu .admin-menu-category {
    height: 60px;
    line-height: 60px;
    padding-left: 25px;
    width: 100%;
    /*#DFE7EA*/
    cursor: pointer;
}

.admin-menu .admin-menu-category:hover {
    background: rgba(0, 0, 0, .2);
}

.admin-menu .admin-menu-category-active {
    background: rgba(0, 0, 0, .1);
    border-right: 3px solid #fdd385;
}

.admin-setting {
    flex-direction: row;
    min-height: 100vh;
}

.admin-content {
    flex-basis: 100%;
    padding: 25px;
}

.min-span {
    font-size: 13px;
    font-weight: bold;
}

.light-silver {
    color: #044f72b4
}

.btn-material-primary {
    background-color: #d1ab66 !important;
    color: #fff !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 2em !important;
    padding: 5px 15px !important;
}

.no-padding {
    padding: 0 !important;
}

.MuiAppBar-colorDefault-9 {
    background: #fff !important;
    border-bottom: 1px solid rgb(236, 236, 236);
    box-shadow: none !important;
}

.btn-material-block-right {
    position: absolute;
    right: 10px;
}

.btn-material-primary i {
    color: #fff;
    font-size: 1em;
    margin: 0 10px;
}

.MuiPaper-elevation4-158,
.MuiPaper-elevation4 {
    box-shadow: none !important;
}

.tab-parent {
    box-shadow: none !important;
    /* border-bottom: 2px solid rgb(230, 230, 230); */
}

.img-prv {
    max-width: 100%;
    max-height: 500px;
}

div[dir="ltr"] {
    padding-left: 0 !important;
}

.table-menu {
    background: #fff !important;
    font-size: 11.5px !important;
}

.table-materials button {
    color: #000 !important;
}

.table-materials .loadMaterialBtn {
    color: #ccc !important;
}

.table-materials .btn-material-primary {
    color: #fff !important;
    margin-top: 6px;
    margin-right: 6px;
}

.table-materials .table-menu {
    width: 100%;
}

.MuiPrivateTabIndicator-colorPrimary-65,
.MuiPrivateTabIndicator-colorPrimary-63 {
    background-color: #00B0FF !important;
}

.FloatingActionButtonZoom-root-1 {
    width: 100% !important;
}

.MuiAppBar-colorDefault-10 {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1) !important;
}

.MuiTypography-body1-106 {
    padding: 15px 0 !important;
}

.flex-left-menu {
    flex-basis: 70px;
    height: 100vh;
    order: 0;
}

.flex-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-children {
    width: 50%;
    display: flex;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex {
    display: flex;
    align-items: center;
}

.d-flex {
    display: flex;
}

.flex-align-center {
    align-items: center;
}

.block-education {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 3px;
    height: 180px;
    border: 1px solid rgb(243, 243, 243);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    overflow: hidden;
    margin-top: 25px;
}

.block-education .education-cover,
.block-education .education-content {
    height: 100%;
    flex-basis: 100%;
    overflow: hidden;
    width: 280px;
    /*22%*/
    min-width: 180px;
}

.block-education .education-content {
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-education .education-content .education-content-children {
    flex-basis: 25%;
    width: 100%;
    align-items: center;
    display: flex;
}

.block-education .education-content .education-content-children:nth-child(2) {
    align-items: flex-start;
    flex-basis: 50%;
}

.block-education .education-cover {
    flex-basis: auto;
}

.block-education .education-content-description {
    font-size: 13px;
}

.block-education .education-content-title {
    font-weight: 500;
}

.block-education .education-content-footer {
    font-size: 14px;
}

.block-education .education-content-footer span {
    margin-right: 25px;
}

.block-education .education-content-stat-avaible {
    font-size: 16px;
    flex-grow: 1;
    text-align: right;
    color: #66D6B0;
}

.education-cover img {
    height: 100%;
    width: 100%;
}

body {
    background: #fff;
    width: 100%;
    color: #505050;
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 0.5px;
}

.clear {
    clear: both;
}

.pdl-300 {
    padding-right: 300px;
}

.pd-25 {
    padding: 25px;
}

.pd-tb-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

pre {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 1px 10px;
}

i {
    color: #d1ab66;
    cursor: pointer;
    position: relative;
}

.hr-max-pop {
    width: 430px;
    position: relative;
    left: -15px;
}

.row-report {
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    width: 340px;
}

.btn-report {
    margin-left: 20px;
    margin-top: 20px;
    width: 150px;
}

.mgl-0 {
    margin-left: 0;
}

.table-report {
    border-radius: 5px !important;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15) !important;
    overflow-x: auto;
}

.table-report-head {
    background: #2f2f2f;
    color: #fff !important;
}

.table-report-head th[scope="col"] {
    color: #fff !important;
}

.btn-report .btnSaveProfile {
    height: 48px;
    position: relative;
    top: 28px;
    transition: .3s ease-in-out;
}

.btnSaveProfile:disabled {
    background-color: #777 !important;
}

.btn-def:disabled {
    background-color: #777 !important;
    color: #fff !important;
}

.reviewImgModal {
    z-index: 2000 !important;
    text-align: center !important;
}

.reviewImgModal img {
    max-width: 500px;
    max-height: 400px;
}

#canvas_t {
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: move;
}

.reviewImgModal h2 {
    text-align: left;
}

.row-report .row-report-title {
    margin-bottom: 10px;
    color: #505050;
    font-size: 18px;
}

.row-report input {
    height: 48px;
}

.row-report .select-report input {
    height: 34px;
}

.row-report .css-10nd86i,
.row-report .css-2o5izw,
.row-report .css-15k3avv,
.row-report .css-vj8t7z {
    min-width: 100% !important;
}

.react-datepicker-popper {
    width: 70% !important;
}

.react-datepicker__month-container,
.react-datepicker {
    width: 100%;
}

.row-stat-test-create {
    width: 70%;
    border: 1px solid #b3e7ff;
    border-radius: 2px;
    height: 40px;
    line-height: 40px;
    margin-left: 1.5%;
}

.css-ssi0ig-container {
    width: 50%;
}

.row-stat-test-create .row-stat-test-create-left {
    height: 39px;
    display: inline-block;
    text-align: center;
    width: 50%;
}

.row-stat-test-create .row-stat-test-create-right {
    height: 39px;
    display: inline-block;
    text-align: center;
    width: 50%;
    border-left: 1px solid #b3e7ff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background-color: #00B0FF;
}

.react-datepicker__navigation--next {
    border-left-color: #00B0FF;
}

.react-datepicker__navigation--previous {
    border-right-color: #00B0FF;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #00B0FF;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #00B0FF;
}

.react-datepicker__header {
    background-color: #f7f7f7;
    border-bottom: 1px solid #e2e2e2;
}

/* .tab-active{
    color: #00B0FF;
    cursor: pointer;
    background: rgb(245, 245, 245);
    border-radius: 5px;
} */
.tab-active {
    color: #00B0FF;
    cursor: pointer;
    border-bottom: 2px solid #00B0FF;
}

.tab-profile {
    position: relative;
    top: 17px;
    padding-bottom: 5px;
}

.w-50 {
    width: 50% !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.text-center {
    text-align: center;
}

.rdw-dropdown-carettoopen {
    border-top: 5px solid #757575 !important;
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
}

.block-content .rdw-dropdown-selectedtext {
    color: #777 !important;
    font-size: 12px !important;
    font-weight: normal !important;
}

.block-content .css-1sontr1 {
    width: 80%;
}

.public-DraftStyleDefault-block {
    margin: 0.1em 0 !important;
}

.rdw-editor-wrapper {
    border: 1px solid #dddddd;
    border-radius: 2px;
}

.rdw-editor-toolbar {
    border: 0 !important;
    background: #fafafa !important;
}

.rdw-editor-main {
    min-height: 200px;
    padding: 5px 15px;
}

.w-50 .css-ssi0ig-container {
    width: 100%;
}

.blockTableNewTest .css-1pcexqc-container {
    width: 50%;
}

.blockTableNewTest input {
    width: calc(100% - 50px);
}

.answersSelection input {
    width: 50%;
}

.answersSelection input[type="checkbox"],
.request-block-test input[type="checkbox"] {
    width: 20px;
}

.rdw-suggestion-option {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 20px;
}

.rdw-suggestion-wrapper {
    position: relative;
    display: inline-block !important;
}

.rdw-suggestion-dropdown {
    position: relative !important;
    display: inherit !important;
    flex-direction: column;
    border: 1px solid #F1F1F1;
    min-width: 100px;
    max-height: 150px;
    /* overflow: auto; */
    background: white;
    z-index: 99999;
    width: 100px;
    /* margin-bottom: 100px; */
    /* margin-top: 100px; */
}

.lesson-page-content p {
    margin: 0 auto;
    word-wrap: break-word;
}

.ck .ck-content p {
    margin: 0;
}

.inputTraining {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    line-height: 38px;
    border-radius: 2px;
    border: 1px solid #dddddd;
    padding: 0 10px;
}

.inputTraining:hover,
.inputTraining:focus {
    border: 1px solid #b6d0dd;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker-popper {
    left: 60px !important;
}

.inputDifficulty {
    width: 30%;
    min-width: 100px;
}

.difficultyHeader {
    width: 100%;
}

.difficultyHeader .difficultyBlock:first-child {
    width: 100px;
    margin-right: 10px;
    text-align: center;
}

.difficultyHeader .difficultyBlock {
    font-size: 13px;
    font-weight: bold;
    float: left;
    width: 30%;
    min-width: 100px;
    margin: 0 10px;
}

.difficultySpanLevel {
    position: relative;
    top: 10px;
    width: 100px;
    text-align: center;
}

span[data-active='true'] {
    border-bottom: 2px solid #D1AB66;
}

.mg-l {
    margin-left: 20px !important;
}

.mglr-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mg-tb-5 {
    margin: 5px 0 !important;
}

.inputTrainingHeight {
    width: 100%;
    height: 80px;
    border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #dddddd;
    padding: 5px 10px;
}

.no-img-training {
    width: 320px;
    height: 200px;
    background: rgb(245, 245, 245);
    border-radius: 5px;
    border: 1px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    margin: auto;
    text-align: center;
    line-height: 198px;
    font-size: 32px;
    color: #777 !important;
    overflow: hidden;
}

.no-img-training i {
    color: #ccc !important;
}

.award-block {
    width: 100%;
    margin: 25px auto;
    border-radius: 3px;
    border: 1px solid rgb(243, 243, 243);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    padding: 25px;
    height: 130px;
}

.award-block .award-desc-block {
    float: left;
    padding: 0 15px;
    width: calc(100% - 80px);
}

.award-desc-block .award-title {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
}

.award-block .award-img {
    float: left;
    width: 80px;
    height: 80px;
}

.award-img img {
    width: 80px;
    height: 80px;
}

.frame-award {
    padding: 25px 10px;
    width: 300px;
    margin: auto;
    border-radius: 15px;
    border: 3px dashed rgb(226, 226, 226);
    text-align: center;
    font-size: 15px;
}

.no-img-award {
    width: 100px;
    height: 100px;
    background: rgb(245, 245, 245);
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    margin: auto;
    text-align: center;
    line-height: 100px;
    font-size: 32px;
    color: #777 !important;
}

.no-img-award i {
    color: #ccc !important;
}

.educationPop {
    width: 400px;
    padding: 20px;
    border-radius: 3px;
    position: absolute;
    top: 70px;
    left: 50%;
    margin-left: -200px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    z-index: 999;
    max-height: 80vh;
    overflow-y: auto;
}

.educationPop .educationSelect {
    height: 50px;
    width: 100%;
    line-height: 48px;
    padding: 0 10px;
    border: 1px solid #00B0FF;
    border-radius: 4px;
    margin-top: 15px;
    color: #00B0FF;
    transition: box-shadow .1s, background .1s, color .1s;
    cursor: pointer;
    margin-bottom: 20px;
}

.educationPop .educationPopTitle {
    height: 50px;
    line-height: 50px;
    font-weight: bold;
}

.educationPop .educationSelect:hover {
    box-shadow: 0 8px 8px -5px rgba(0, 0, 0, .2);
    background: #00B0FF;
    color: #fff;
}

.educationAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(243, 173, 67);
    color: #fff;
    line-height: 38px;
    text-align: center;
    float: left;
    margin-right: 10px;
    position: relative;
    top: 5px;
}

.cur-p {
    cursor: pointer;
}

.color-shadow {
    color: #4C4C4C !important;
}

.font-min {
    font-size: 13px;
}

i .new {
    position: absolute;
    right: -2px;
    top: -2px;
    width: 8px;
    height: 8px;
    background: rgb(233, 71, 71);
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4)
}

.fl-left {
    float: left;
}

.fl-right {
    float: right !important;
}

.w-45 {
    width: 45%;
}

.w-100 {
    width: 100%;
}

.mt-0 {
    margin-top: 0;
}

.mt-20 {
    margin-top: 20px;
}

.bg-pur {
    background: #F2F4FF;
}

.radius-20 {
    border-radius: 20px;
}

.radius-15 {
    border-radius: 15px;
}

.radius-10 {
    border-radius: 10px;
}

.p-20 {
    padding: 20px;
}

.bd-pure {
    border: 1px solid #F2F4FF;
}

.bg-pure {
    background: #F2F4FF;
}

.tx-right {
    text-align: right;
}

.not-found {
    width: 100%;
    height: 400px;
    background: url(../../../img/404.png) no-repeat center center;
    margin-top: 50px;
}

.big-title {
    color: rgb(240, 47, 47);
    font-size: 36px;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
}

.marker-primary {
    padding: 0px 5px;
    font-size: 14px;
    color: rgba(45, 199, 109, 0.6);
    border-radius: 2px;
    padding-bottom: 2px;
    position: relative;
    margin-left: 20px;
    top: 2px;
}

.btnChatOn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    background: rgb(83, 148, 253);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    display: none;
}

.btnChatOff {
    color: #fff !important;
    position: relative;
    top: 30px;
    display: none !important;
}

.marker-warning {
    padding: 0px 5px;
    font-weight: bold;
    font-size: 13px;
    color: rgba(199, 45, 65, 0.6);
    border-radius: 2px;
    padding-bottom: 2px;
    position: relative;
    margin-left: 20px;
    top: 2px;
}

.chat-box .search-chat-user {
    width: 100%;
    padding: 0;
    margin: auto;
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    min-height: 45px;
    padding-left: 50px;
    border-bottom: 1px solid rgb(237, 241, 247);
    color: #777;
}

.chat-box {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.chat-box .name-box {
    width: 25%;
    height: 100%;
    /* border-right: 1px solid rgb(237, 241, 247); */
    float: left;
    position: relative;
    border-right: 1px solid rgb(237, 241, 247);
}

.chat-box .chat-row-name,
.chat-box .chat-row {
    width: 100%;
    height: 45px;
    line-height: 43px;
    padding: 0 20px;
    border-bottom: 1px solid rgb(237, 241, 247);
    position: relative;
}

.chat-box .chat-row i {
    color: rgb(209, 218, 231);
    padding-right: 10px;
    z-index: 5;
}

.chat-box .chat-row i:hover {
    color: rgb(191, 199, 211);
}

.name-box .chat-row-name {
    cursor: pointer;
}

.name-box .chat-row-active,
.name-box .chat-row-name:hover {
    background: #3B86FF;
    color: #fff;
    border-bottom: 1px solid rgb(83, 148, 253);
}

.name-box .chat-notice-msg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(240, 87, 87);
    color: #fff;
    float: right;
    line-height: 19px;
    text-align: center;
    position: relative;
    top: 12.5px;
    font-size: 12px;
}

.center {
    text-align: center;
}

.name-box .add-user {
    width: 90%;
    height: 40px;
    margin: auto;
    margin-top: 15px;
    border-radius: 15px;
    background: rgb(243, 246, 250);
    border: 2px dashed rgb(209, 218, 231);
    text-align: center;
    line-height: 32px;
    color: rgb(183, 192, 202);
    font-weight: bold;
    font-size: 13px;
    opacity: 1;
    cursor: pointer;
    position: relative;
}

.chat-filter-box {
    position: relative;
    top: -40px;
    margin: 0 5%;
    background: #fff;
    z-index: 1;
    border-radius: 3px;
    width: 90%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    opacity: 0;
    display: none;
}

.noImageCoverFix {
    padding: 10px;
}

.filter-row {
    padding: 10px 10px;
    text-align: center;
    border-bottom: 1px solid rgb(237, 241, 247);
    cursor: pointer;
}

.filter-row i {
    float: right;
    position: relative;
    top: 5px;
    color: rgb(191, 199, 211) !important;
    box-shadow: none !important;
}

.filter-row:hover {
    background: rgb(237, 241, 247);
}

.filter-row-input:hover {
    background: #fff;
}

.chat-filter-box-active {
    opacity: 1 !important;
    display: inline-block !important;
}

.chat-filter-box input {
    width: 80%;
    border: 0;
    color: #3B86FF;
    float: left;
}

.chat-filter-box .close-filter {
    width: 20%;
    float: right;
}

.name-box .add-user i {
    color: rgb(183, 192, 202);
    font-size: 10px;
    margin-left: 1px;
}

.name-box .add-user:hover {
    opacity: 1;
}

.chat-box .content-box {
    width: 75%;
    height: 100%;
    float: right;
    border-left: 1px solid rgb(237, 241, 247);
}

.content-box .chat-row {
    height: 70px;
    padding: 0 20px;
    line-height: 70px;
}

.content-box .chat-row .user-chat-block {
    height: 100%;
    padding: 10px 0;
    line-height: 24px;
}

.user-chat-block .chat-user-status {
    color: rgb(186, 196, 212);
    font-size: 13px;
}

.user-header-block .ava-chat-fix {
    width: 40px;
    height: 40px;
    line-height: 38px;
}

.user-header-pop {
    display: none
}

.text-left {
    text-align: left
}

.user-header-block:focus .user-header-pop {
    display: inline-block;
}

/* .user-header-block div:focus{
    background: #000 !important;
} */
/* .user-header-block:active .user-header-pop{
    display: inline-block;
    visibility: visible;
} */
/* .user-header-block:focus > .user-header-pop{
    display: inline-block;
}
.user-header-block:focus ~ .user-header-pop{
    display: inline-block;
} */

.ava-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.ava-chat {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url(../../../img/ava.jpg) no-repeat;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    background-size: 100%;
    position: relative;
    float: left;
    margin-right: 15px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
}

.ava-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    background-size: 100%;
    position: relative;
    float: left;
    margin-right: 15px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
}

.content-box .chat-content-messages {
    width: 100%;
    padding: 15px 20px;
    height: 70vh;
    overflow-y: auto;
}

.chat-content-messages .msg {
    width: 100%;
    margin-top: 25px;
}

.chat-content-messages .msg-time {
    color: #ccc;
    font-size: 12px;
    width: 100%;
}

.chat-content-messages .msg:first-child {
    margin-top: 0;
}

.chat-content-messages .opponent-msg,
.chat-content-messages .my-msg {
    width: auto;
    max-width: 75%;
    /* border-radius: 10px 10px 10px 0; */
    /* background: #EDF0F5; */
    padding: 10px;
    font-size: 14px;
    /* float: left; */
}

.chat-content-messages .opponent-msg {
    border-radius: 10px 10px 10px 0;
    background: #EDF0F5;
    float: left;
}

.chat-content-messages .opponent-msg~.msg-time {
    float: left;
    text-align: left;
}

.chat-content-messages .my-msg~.msg-time {
    float: right;
    text-align: right
}

.chat-content-messages .my-msg {
    border-radius: 10px 10px 0px 10px;
    background: #3B86FF;
    float: right;
    color: #fff;
}

/* .content-box .chat-create{
} */
.chat-create .chat-create-logo {
    width: 100px;
    height: 100px;
    background: url(../../../img/talk.png) no-repeat center center;
    background-size: 100px;
    margin: 50px auto;
}

.chat-create .chat-create-form {
    width: 50%;
    margin: 50px auto;
    text-align: center;
}

.chat-create-form input {
    width: 90%;
    margin-top: 20px;
    padding: 5px 10px;
    border: 1px solid rgb(226, 230, 235);
    border-radius: 2px;
}

.chat-create-form .targetUserSearch {
    padding: 5px 0;
    border: 1px solid rgb(226, 230, 235);
    border-top: 0;
    margin: 0 5%;
    width: 90%;
    cursor: pointer;
}

.chat-create-form .targetUserSearch:hover {
    background: #3B86FF;
    color: #fff;
}

.chat-create-form button {
    padding: 10px 25px;
    color: #fff;
    background: #b2cefc;
    border-radius: 2px;
    margin-top: 50px;
    border: 0;
    cursor: pointer;
}

.chat-create-form button:hover {
    background: #a3c5ff;
}

.content-box .chat-content-footer {
    width: 100%;
    padding: 15px 20px;
    border-top: 1px solid rgb(237, 241, 247);
}

.chat-form input {
    color: #3B86FF;
    border: 0;
    width: 100%;
}

.content-box .chat-content-footer i {
    color: #3B86FF;
    margin-right: 10px;
}

.result-test-block {
    max-width: 580px;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.result-test-block .result-test-tasck {
    width: 96px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    display: inline-block;
    margin: 11px;
    border: 2px solid #FF5252;
    border-radius: 2px;
    color: #545454;
    background: linear-gradient(0deg, rgba(255, 82, 82, 0.4), rgba(255, 82, 82, 0.4)), #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    margin-left: 0;
    margin-right: 22px;
}

.result-test-block .result-test-tasck-success {
    border: 2px solid #38D39F;
    background: #fff;
}

/* .result-test-block .result-test-tasck:nth-child(6n + 6){
    margin-left: 0;
}
.result-test-block .result-test-tasck:nth-child(5n + 5){
    margin-right: 0;
}
.result-test-block .result-test-tasck:first-child{
    margin-left: 0;
}*/
.block-content {
    padding: 20px 30px;
    border-radius: 3px;
    position: relative;
    transition: all .3s;
}

.block-content a {
    color: #d1ab66 !important;
    font-size: 14px;
    font-weight: bold;
}

.color-blue {
    color: #d1ab66 !important;
}

.block-content .block-title {
    color: #5c698d;
    /* border-bottom: 1px solid rgb(233, 236, 240); */
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    color: #505050;
    /* font-weight: bold; */
}

.mobile-navbar {
    width: 100%;
    height: 90px;
    line-height: 90px;
    font-size: 24px;
    color: #5f5f5f;
    margin-bottom: 25px;
    box-shadow: 0 0 5px #ccc;
    text-align: center;
}

.pd-15 {
    padding: 15px;
}

.admin-content .error_msg_test {
    min-height: 55px;
    line-height: 35px;
}

.error_msg_test {
    color: #fff;
    background: rgb(255, 112, 112);
    border-radius: 3px;
    padding: 10px;
}

.error_msg_test i {
    color: #fff !important;
}

.error_msg_test .hr {
    background: rgba(255, 255, 255, 0.5);
}

.error_msg_test .btn-red {
    background: rgb(255, 158, 158);
    box-shadow: none;
    padding: 0px 10px;
    border-radius: 4px;
    transition: background .3s;
}

.error_msg_test .btn-red:hover {
    background: rgb(255, 112, 112);
}

.block-content .block-title i {
    font-size: 18px;
}

.block-content .block-child {
    width: 100%;
}

.block-content .hr {
    height: 1px;
    width: 100%;
    margin: 20px 0;
    background: rgb(233, 236, 240);
}

.hr {
    height: 1px;
    width: 100%;
    margin: 20px 0;
    background: rgb(233, 236, 240);
}

.btn-green {
    background: #2DC76D;
    padding: 2px 15px;
    border-radius: 15px;
    color: #fff;
    box-shadow: 0 0 15px #D6E3FF;
}

.btn-red {
    background: rgb(255, 88, 88);
    padding: 8px 25px;
    border-radius: 30px;
    color: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    text-align: center;
    transition: box-shadow .3s;
    cursor: pointer;
}

.btn-red i {
    color: #fff !important;
}

.btn-red:hover {
    box-shadow: 0 0px 0px transparent;
}

.font-green {
    color: #66D6B0 !important;
}

.card-block-primary,
.card-block-warning,
.card-block-pur,
.card-block {
    background: #fff;
    /* border: 1px solid #F2F4FF; */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    overflow: hidden;
    height: 200px;
    cursor: pointer;
}

.card-block {
    border: 1px solid #F2F4FF;
}

.card-block-warning {
    border: 1px solid rgba(199, 45, 65, 0.6);
}

.card-block-primary {
    border: 1px solid rgba(45, 199, 109, 0.6);
}

.card-block-pur {
    border: 1px solid rgb(236, 236, 236);
}

.card-title {
    color: #505050;
    font-weight: 500;
    font-size: 18px;
}

.card-text {
    color: #505050;
    font-weight: normal;
    line-height: 18px;
    font-size: 12px;
}

.card-content {
    float: right;
    width: 85%;
    padding-left: 15px;
    padding: 20px;
    height: 100%;
    position: relative;
}

.no-img {
    background: #ccc;
    height: 100%;
    width: 100%;
    text-align: center;
    line-height: 120px;
    font-size: 35px;
}

.no-img i {
    color: #fff !important;
}

.card-img {
    width: 14%;
    float: left;
    max-height: 100%;
    text-align: left;
    overflow: hidden;
    position: relative;
    padding: 0;
    border-radius: 0;
}

.card-img img {
    border-radius: 0;
    height: 200px;
}

.card-footer-status {
    position: absolute;
    bottom: 20px;
    width: 95%;
}

.card-footer-inline {
    padding: 5px 10px;
    border-radius: 5px;
    color: #505050;
    font-size: 12px;
    font-weight: bold;
    display: inline;
    margin-right: 20px;
}

.card-footer-inline i {
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    position: relative;
    top: -1px;
    border-radius: 50%;
    font-size: 12px;
    color: #00B0FF;
    margin-right: 5px;
}

.number {
    position: relative;
    display: inline-block;
    width: 100%;
}

.number i {
    color: rgb(76, 179, 76);
}

.number .fa-trash-alt {
    color: rgb(216, 86, 86) !important;
    position: absolute;
    left: 20px;
}

.number-educaction {
    width: 24px;
    height: 24px;
    color: #505050;
    position: relative;
    left: 15px;
    border-radius: 50%;
    line-height: 24px;
    text-align: center;
    display: inline-block;
    border: 1px solid #00B0FF;
    font-size: 14px;
}

.educ-chat-teacher {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ccc;
    padding: 0 15px;
}

.educ-chat-teacher .ava-tc {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: url(../../../img/teacher.jpg) no-repeat center center;
    background-size: 100%;
    border: 1px solid #01ADFF;
    margin-right: 15px;
    float: left;
}

.name-tc {
    font-size: 13px;
    font-weight: bold;
    float: left;
}

.stat-tc {
    font-size: 13px;
    float: left;
}

.educ-chat-block {
    overflow: auto;
    width: 100%;
    height: 500px;
    padding: 15px;
}

.educ-chat-block .educ-msg-block {
    clear: both;
    margin-top: 15px;
}

.educ-chat-block .msg-tc {
    max-width: 80%;
    float: right;
    background: #6594A8;
    padding: 5px 10px;
    border-radius: 15px 15px 0 15px;
    color: #fff;
    font-size: 14px;
}

.mt-10 {
    margin-top: 10px !important;
}

.fl-r {
    float: right;
}

.mr-50 {
    margin-right: 5% !important;
}

.educ-chat-block .msg-usr {
    max-width: 80%;
    float: left;
    background: #DFE7EA;
    padding: 5px 10px;
    border-radius: 15px 15px 15px 0px;
    color: #505050;
    margin-top: 10px;
    font-size: 14px;
}

.educ-textarea {
    width: 90%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-left: 5%;
}

/* .card-footer-content i::after{
    content: '';
    position: absolute;
    top: -2.5px;
    left: -2.5px;
    width: 20px;
    height: 20px;
    border: 1px solid #BDBDCE;
    border-radius: 50%;
    background: transparent;
} */
.toggle-content {

    display: block
}

.fade-toggle {
    display: none;
}

.spinner-block {
    height: 70vh;
}

.spinner-block .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
}

.spinner {
    margin: 0px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.stepper svg {
    /* background: #3F9FFF; */
}

.spinner>div {
    background-color: #3F9FFF;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 0 0.5px;
    border-radius: 10px;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {

    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.1)
    }

    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes sk-stretchdelay {

    0%,
    40%,
    100% {
        transform: scaleY(0.1);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

.icon-red {
    color: rgb(190, 76, 76) !important;
}

.icon-large {
    font-size: 16px !important;
}

.icon-silver {
    color: #ccc !important;
    transition: all .2s
}

.icon-silver:hover {
    color: rgb(180, 180, 180) !important;
}

.editProfile {
    width: 400px;
}

.editProfile .ava-chat,
.editProfile .ava-profile {
    width: 80px;
    height: 80px;
    left: 50%;
    margin: 15px auto;
    margin-left: -40px;
    cursor: pointer;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
}

.editProfile .ava-chat i {
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    background: #fff;
    width: 25px;
    height: 25px;
    line-height: 23px;
    text-align: center;

}

.edit-test {
    margin-top: 2.5px;
    margin-left: 15px;
}

.answersSelection .fa-times {
    color: rgb(214, 57, 57);
}

.editProfile .menu {
    position: relative;
}

.editProfile .nameInput {
    width: 70%;
    margin: auto;
    text-align: left !important;
}

.w-100 {
    width: 100% !important;
}

.editProfile .savePassword {
    color: #00B0FF;
    font-size: 12px;
    cursor: pointer;
    width: 70%;
    margin: auto;
    text-align: left;
}

/* .editProfile  .reset-password{
    padding-right: 70px;
} */
.editProfile>.pop-up {
    width: 100px !important;
}

.editProfile input {
    width: 90%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #777;
}

.editProfile .min-input-edit {
    width: 45%;
}

.editProfile #material-input {
    width: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;
    color: 0;
}

.editProfile input:hover,
.editProfile input:focus {
    border: 1px solid #00B0FF;
}

.blockTableNewTest {
    width: 70%;
    background: rgb(236, 236, 236);
    padding: 25px;
    border-radius: 5px;
    margin: 25px;
    position: relative;
}

.blockTableNewTest .css-10nd86i {
    max-width: 50% !important;
}

.request-block-test .input-checkbox {
    position: relative;
    top: 8px;
}

.blockTableNewTest .css-vj8t7z {
    width: 100% !important;
    min-width: 100%;
}

.blockTableNewTest .request-block-test {
    width: 40%;
    position: absolute;
    right: 25px;
    top: 45px;
    height: 40px;
    line-height: 40px;
}

.btnSaveProfile {
    background: #d1ab66;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    padding: 8px 25px;
    border: 0;
    border-radius: 3px;
    color: #fff;
    margin: auto;
    border-radius: 30px;
    transition: box-shadow .3s;
    cursor: pointer;
}

.default-btn {
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    padding: 8px 25px;
    border: 1px solid #d1ab66;
    border-radius: 3px;
    color: #fff;
    margin: auto;
    border-radius: 30px;
    transition: box-shadow .3s;
    cursor: pointer;
    color: #d1ab66;
}

.default-btn:hover,
.btnSaveProfile:hover {
    box-shadow: 0 0px 0px transparent;
}

.fixProfileName {
    padding-top: 10px;
}

.img_cover_material {
    width: 320px;
    height: 200px;
}

.btnAlert {
    background: #fff;
    padding: 8px 25px;
    border: 0;
    border-radius: 3px;
    color: #EF5C5C;
    border: 1px solid #EF5C5C;
    margin: auto;
    border-radius: 30px;
    cursor: pointer;
}

.h100 {
    min-height: 100vh;
}

.left-content {
    /* background: #BDBDCE; */
    background: #2f2f2f;
    width: 300px;
    height: 100%;
    z-index: 1;
    color: #ccc;
    box-shadow: 0px 0 10px rgba(0, 0, 0, .03);
    /* border-radius: 0 15px 15px 0; */
    /* margin-left: 20px; */
    transition: width .3s;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 15px;
}

.left-content a {
    text-decoration: none;
    outline: 0;
}

.left-content i {
    font-size: 18px;
    /* color: #20a6fe; */
    color: #fff;
    margin-right: 25px;
    width: 25px;
}

.left-content .logo {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 70px;
    color: #fff;
    margin: auto;
    /*background: url(../../../img/logo2.png) no-repeat center center;*/
    background-size: 100%;
}

.left-content .menu {
    padding: 15px 20px;
    padding-left: 25px;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    color: #fff;
    transition: background .1s, color .1s;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.left-content .menu-active {
    background: rgba(240, 245, 251, .1);
    color: #fff;
    border-left: 5px solid #fdd385;
}

.left-content .menu:last-child {
    box-shadow: none;
}

.fix-table-programms .table-column:nth-child(1),
.fix-table-programms .table-column:nth-child(6) {
    flex-basis: 350px;
}

.fix-table-programms .table-column:nth-child(7) {
    flex-basis: 700px;
}

.fix-table-programms .table-column:nth-child(3),
.fix-table-programms .table-column:nth-child(4),
.fix-table-programms .table-column:nth-child(5) {
    flex-basis: 700px;
}

.fix-table-courses .table-column:nth-child(1),
.fix-table-courses .table-column:nth-child(6) {
    flex-basis: 350px;
}

.fix-table-courses .table-column:nth-child(7) {
    flex-basis: 700px;
}

.fix-table-courses .table-column:nth-child(3),
.fix-table-courses .table-column:nth-child(4),
.fix-table-courses .table-column:nth-child(5) {
    flex-basis: 700px;
}

.fix-table-lectures .table-column:nth-child(1),
.fix-table-lectures .table-column:nth-child(5) {
    flex-basis: 250px;
}

.fix-table-lectures .table-column:nth-child(6) {
    flex-basis: 600px;
}

.fix-table-lectures .table-column:nth-child(3),
.fix-table-lectures .table-column:nth-child(4) {
    flex-basis: 600px;
}

.menu-min {
    width: 70px;
    text-align: center;
    padding: 15px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-min span {
    display: none;
}

.menu-min i {
    margin-right: 0;
}

.menu-min .menu {
    padding: 15px 0;
}

.left-content .menu:hover {
    /* background: #EEEFF3; */
    background: #F0F5FB;
    color: #2f2f2f;
}

.left-content .menu:hover>i {
    color: #2f2f2f !important;
}

.btnSaveProfile {
    cursor: pointer;
}

.btnSaveProfile i {
    color: #fff;
    position: relative;
    left: 5px;
}

.material-menu {
    height: 40px;
    line-height: 38px;
    margin-right: 50px;
    color: #828282;
    cursor: pointer;
}

.btn-material-block {
    width: 200px;
    height: 50px;
    position: relative;
}

/* .btnSaveProfile:focus ~ .material-menu-pop{
    transform: scale(1);
} */
.material-menu-pop {
    width: 200px;
    padding: 0 10px;
    background: #fff;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 999;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    transform: scale(1);
    transition: transform 0.2s;
}

.material-menu-pop .material-menu-pop-cat {
    height: 40px;
    line-height: 35px;
    font-size: 14px;
    padding: 5px 0;
    cursor: pointer;
}

.material-menu-pop .material-menu-pop-cat:hover {
    color: #00B0FF;
}

.material-menu-pop::before {
    content: '';
    position: absolute;
    right: 10px;
    top: -10px;
    border: 5px solid transparent;
    border-bottom: 5px solid #fff;
}

.material-menu:hover {
    color: #00B0FF;
}

.material-menu-active {
    color: #00B0FF;
    border-bottom: 3px solid #00B0FF;
}

.btnSaveProfileFix {
    top: 2px !important;
}

.table {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #F2F2F2;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
}

.table .table-header {
    width: 100%;
    background: #2f2f2f;
    height: 50px;
    line-height: 48px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
}

.table .table-column {
    /* width: 14.28%; */
    float: left;
    height: 50px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-basis: 100%;
    position: relative;
}

.table .table-column-fix {
    /* width: 14.285%; */
    float: left;
    height: 50px;
    text-align: center
}

.table .table-column-fix:nth-child(1) {
    width: 12.285%;
}

.table .table-column-fix:nth-child(2) {
    width: 16.285%;
}

.table-line {
    position: relative;
}

.have-test {
    color: rgb(81, 184, 81) !important;
    font-size: 15px !important;
}

.dont-test {
    color: rgb(184, 81, 81) !important;
    font-size: 15px !important;
}

.main-modal-review {
    display: flex;
    width: 550px;
    flex-direction: row;
}

.main-modal-review .left-panel-modal-review {
    flex-basis: 100px;
    /* border-right: 1px solid #f2f2f2;
    background: #f2f2f2; */
}

.main-modal-review .left-panel-modal-review button {
    width: 100%;
    margin: 10px 0;
    box-shadow: none;
}

.main-modal-review .panel-modal-review {
    text-align: center;
    flex-basis: 100%;
}

.main-modal-review .panel-modal-review img {
    max-width: 95%;
}

.table-line .editMaterialLine {
    position: absolute;
    right: -10px;
    top: 0px;
    opacity: 0;
    font-size: 18px;
    z-index: 18;
    transition: right .3s, opacity .3s;
}

.toggle-table-column {
    background: #F2F2F2;
    animation: toggle-table-column 2s forwards;
}

@keyframes toggle-table-column {
    100% {
        background: #fff;
    }
}

.table-line .orderDown {
    position: absolute;
    left: 10px;
    top: 0px;
    opacity: 1;
    font-size: 18px;
    z-index: 18;
    transition: left .3s, opacity .3s;
}

.table-line:hover .orderDown {
    left: 10px;
    opacity: 1;
}

.table-line .orderDown i {
    color: #ccc !important;
}

.table-line .orderDown i:hover {
    color: #777 !important;
}

.table-line .orderUp {
    position: absolute;
    right: 10px;
    top: 0px;
    opacity: 1;
    font-size: 18px;
    z-index: 18;
    transition: right .3s, opacity .3s;
}

/* .table-line:hover .orderUp{
    left: 10px;
    opacity: 1;
} */
.table-line .orderUp i {
    color: #ccc !important;
}

.table-line .orderUp i:hover {
    color: #777 !important;
}

.table-line .editMaterialTest {
    right: 40px !important;
}

.table-line .editMaterialLine i {
    color: #ccc !important;
}

.table-line .editMaterialLine i:hover {
    color: #777 !important;
}

.react-pdf__Page__canvas {
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .4);
}

.table-line:hover .editMaterialLine {
    opacity: 1;
    right: 10px;
}

.table-line .table-column-fix {
    border-right: 1px solid #F2F2F2;
}

.table-line .table-column-fix:last-child {
    border-right: 0;
}

.table .table-line {
    border-bottom: 1px solid #F2F2F2;
    height: 50px;
    line-height: 48px;
    font-size: 12px;
    font-weight: normal;
    color: #545454;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.table .table-line:hover {
    background: #F2F2F2;
}

.table-line .table-column {
    border-right: 1px solid #F2F2F2;
}

.table-line .table-column:last-child {
    border-right: 0;
}

.quote {
    width: 100%;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid rgb(236, 236, 236);
    margin: 25px 0;
    color: rgb(166, 172, 187);
    margin-top: 50px;
}

.btn-active-educ {
    padding: 3px 15px;
    border-radius: 5px;
    color: #fff;
    background: rgb(140, 219, 94);
}



.right-content {
    flex-basis: 100%;
    z-index: 0;
    background: #fff;
    transition: padding .3s;
    order: 1;
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    display: flex;
    overflow: hidden;
}

.user-right-content {
    flex-basis: 100%
}

.right-content a {
    color: #00B0FF;
}

.right-content-full {
    padding-left: 300px;
}

.user-right-profile,
.user-right-content {
    padding: 0px 1%;
}

.datapicker-ui {
    width: 90%;
    border-bottom: 0 !important;
}

.form button span {
    color: #777 !important;
}

form .table-menu {
    margin-bottom: 0 !important;
}

form .table-menu span {
    padding: 0;
}

.authComponent .editProfile {
    width: 100% !important;
}

.datapicker-ui input {
    width: 100% !important;
}

.header-right-content {
    z-index: 1;
    width: 100%;
    flex-basis: 70px;
    padding: 0 1%;
    background: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
    position: relative;
}

.relative {
    position: relative;
}

.pagin {
    font-size: 14px;
    color: #505050;
    font-weight: bold;
}

.user-right-profile {
    z-index: 0;
    padding-top: 15px;
}

.header-right-content i {
    margin-right: 20px;
    font-size: 18px;
}

.header-right-content .notice {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    color: #00B0FF;
    position: relative;
    top: -2px;
    font-size: 24px;
}

.header-right-content .title {
    font-weight: bold;
    font-size: 24px;
    color: #000;
}

.header-right-content .avatar-min {
    border-radius: 50%;
    max-width: 40px;
    max-height: 40px;
    margin-right: 20px;
    box-shadow: 0 0 6px rgb(191, 191, 202);
}

.user-header-block {
    line-height: 18px;
    height: 100%;
    transition: box-shadow .3s, border-radius .3s, background .3s;
    margin-right: 25px;
    position: relative;
    text-transform: capitalize;
    border: 0;
    background: #fff;
    outline: none;
}

.notif-header-block {
    line-height: 18px;
    height: 100%;
    position: relative;
    display: inline-block;
    transition: box-shadow .3s, border-radius .3s, background .3s;
    position: relative;
}

.user-header-block:hover {
    box-shadow: 9px 0px 6px -5px rgba(0, 0, 0, 0.05), -9px 0px 6px -5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    cursor: pointer;
    background: #f9f9f9;
}

.user-header-block i {
    margin-right: 0;
}

.notif-header-block .user-header-pop {
    right: 15px;
    animation: notif-header-pop .3s forwards ease;
}

.notif-msg {
    float: right;
    width: 85%;
    font-size: 14px;
}

.notif-icon {
    float: left;
    width: 15%;
}

.user-header-pop {
    position: absolute;
    top: 80px;
    right: 0;
    width: 300px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, .1);
    opacity: 0;
    z-index: 3;
    cursor: default;
    color: #7E7E7E;
    animation: user-header-pop .3s forwards ease;
    padding-bottom: 20px;
}

.user-header-pop::before {
    content: '';
    position: absolute;
    border: 8px solid transparent;
    border-bottom: 8px solid #fff;
    top: -16px;
    right: 10px;
}

@keyframes user-header-pop {
    100% {
        top: 55px;
        opacity: 1;
    }
}

@keyframes notif-header-pop {
    100% {
        top: 40px;
        opacity: 1;
    }
}

.min-block-left {
    float: left;
    width: 70%;
    padding-right: 25px;
    color: #000;
    padding-left: 25px;
    padding-top: 25px;
}

.min-block-right {
    width: 25%;
    background: rgb(243, 243, 243);
    border-radius: 3px;
    overflow-y: auto;
    padding-bottom: 25px;
    height: 100%;
    position: fixed;
    right: 0;

}

.min-pd-cont {
    padding: 5px 30px;
}

.title-min {
    font-size: 18px !important;
    font-weight: bold !important;
}

.font-m {
    font-size: 12px !important;
}

.min-block-right .min-block-right-title {
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 18px;
    background: #2f2f2f;
    height: 80px;
    line-height: 80px;
    padding: 0 15px;
}

.min-block-right .min-block-right-title-finish {
    padding: 10px;
    color: #FF5252;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 50px;
}

.min-block-right .min-block-right-coub {
    /* margin-left: 4.8px;
    margin-top: 10px; */
    margin: 10px 15.5px;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #fff;
    box-shadow: 0 5px 3px -3px rgba(0, 0, 0, .05);
}

.min-block-right .min-block-right-inf {
    padding: 0 25px;
    padding-bottom: 100px
}

.test-stat {
    width: 580px;
    height: 50px;
    border-radius: 3px;
    border: 1px solid #B3E7FF;
    line-height: 48px;
    margin-top: 25px;
}

.test-quest {
    width: 50%;
    border-right: 1px solid #B3E7FF;
    height: 100%;
    text-align: center;
}

.min-block-left button {
    background: #fff;
    border: 0;
    color: #777;
    text-align: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    margin-right: 25px;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 25px;
    border-radius: 25px;
}

.min-block-left .btn-next {
    background: #d1ab66;
    color: #fff;
}

.min-block-left .btn-next:disabled {
    background-color: #777 !important;
}

.pop-up {
    position: absolute;
    width: 430px;
    height: auto;
    top: 20px;
    left: 50%;
    margin-left: -205px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    padding: 20px 15px;
    z-index: 999;
    border-radius: 3px;
}

.pop-up .pop-up-title {
    text-align: center;
    color: #777;
    font-size: 16px;
    height: 30px;
    line-height: 28px;
}

.pop-up .achivement-block {
    float: left;
    width: 100px;
    margin-top: 20px;
    min-height: 100px;
    text-align: center;
    font-size: 13px;
    background: url(../../../img/achieve.png) no-repeat top center;
    background-size: 80px;
    padding-top: 100px;
}

.pop-up .pop-up-title i {
    float: right;
    font-size: 25px;
}

.btn-lighting {
    background: #fff;
    border: 0;
    color: #777;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 14px;
    padding: 8px 25px;
    border-radius: 25px;
}

.min-block-left textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.test-timer {
    width: 50%;
    float: right;
    border-left: 1px solid #B3E7FF;
    height: 100%;
    text-align: center;
}

.user-header-pop .pop-header {
    padding: 20px 0;
    border-bottom: 1px solid #EFEFEF;
    height: 80px;
    text-align: center;
    font-size: 12px;
}

.pop-header .left-block,
.pop-header .right-block {
    float: left;
    height: 100%;
    width: 149.5px;
    padding: 0 5px;
}

.left-block b,
.right-block b {
    font-size: 14px;
}

.pop-header .hr-vertical {
    width: 1px;
    height: 100%;
    float: left;
    background: #74ABC0;
}

.user-header-pop .menu {
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
}

.user-header-pop .menu i {
    top: 0;
}

.notif-no-read {
    background: #f5f5f5;
    border-bottom: 1px solid #fff;
}

.user-header-pop .menu-unhover {
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
}

.user-header-pop .menu:hover {
    background: #f9f9f9;
}

.user-header-pop i {
    margin-right: 20px;
}

.user-right-profile .avatar {
    width: 80px;
    box-shadow: 0 0 12px rgb(191, 191, 202);
    border: 1px solid #fff;
    border-radius: 50%;
    margin-right: 20px;
    float: left;
}

.user-right-profile .user-profile-content {
    padding: 15px 0;
    float: left;
}

.user-right-profile .user-right-rating {
    margin-left: 50px;
    padding: 15px 0;
    text-align: center
}

.user-right-rating .left-block,
.user-right-rating .right-block {
    float: left;
    height: 100%;
    text-align: center;
    padding: 0 20px;
}

.user-right-rating .left-block {
    border-right: 1px solid #0C87BF;
}

.user-right-rating b,
.user-right-rating b {
    font-size: 14px;
}

.user-right-rating .hr-vertical {
    width: 1px;
    height: 100%;
    float: left;
    background: #74ABC0;
}

.achivement-right-content img {
    max-width: 80px;
    border-radius: 50%;
    margin: auto;
}

.achivement-right-content .achievement {
    width: 25%;
    height: 82px;
    text-align: center;
    float: left;
}

.achivement-right-content .complete {
    border: 1px solid #3ACC67;
    position: relative;
}

.achivement-right-content .complete::before {
    content: '\2713';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #3ACC67;
    border-radius: 50%;
    bottom: 0px;
    right: 0;
    z-index: 1;
    color: #fff;
    text-align: center;
    line-height: 20px;
}


.user-right-content .num-education {
    width: 25px;
    height: 25px;
    line-height: 23px;
    text-align: center;
    border: 1px solid #0C87BF;
    border-radius: 50%;
    display: inline-block;
    margin: 0 15px;
}

.user-right-content .education-block {
    width: 100%;
    height: auto;
    padding: 1px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    margin: 30px 0;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.education-block .left {
    width: 20%;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    float: left;
    overflow: hidden;
    border-right: 1px solid #EFEFEF;
    text-align: center;
}

.education-block .left img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.education-block .right {
    width: 80%;
    height: 100%;
    float: right;
    padding: 15px 20px;
    padding-bottom: 60px;
}

.education-block .bottom {
    position: absolute;
    width: 80%;
    right: 0;
    bottom: 0;
    padding: 0 20px;
    padding-bottom: 10px;
    font-size: 14px;
}

.education-block .bottom .bottom-block {
    margin-right: 20px;
    display: inline;
}

.education-block .bottom .fl-right {
    cursor: pointer;
    color: rgb(58, 190, 135);
    height: 100%;
    padding: 0 10px;
    border-radius: 10px;
    transition: background .2s, color .2s;
}

/* .education-block .bottom .fl-right:hover{
    background: rgb(58, 190, 135);
    color: #fff;
} */

.education-test .bottom .fl-right {
    background: #0C87BF;
    color: #fff;
}

.education-test .bottom .fl-right i {
    color: #fff;
    margin-left: 15px;
}

/* .fa-angle-down:hover ~ .toggle_block{
    max-height: 0;
} */
.toggle_block {
    overflow: hidden;
    transition: max-height .5s, margin .1s;
    max-height: 1000px;
    border-bottom: 1px solid #e7e7e7;
    margin: 15px 0;
}

.toggle_block_hidden {
    max-height: 0;
}

.calendar-block {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    overflow: hidden;
    margin: 25px 0;
}

.calendar-block .calendar-head {
    padding: 10px 0;
    text-align: center;
    color: #fff;
    background: #045072;
    width: 100%;
}

.calendar-body .calendar-bday {
    width: 14.2857%;
    border: 1px solid #EFEFEF;
    float: left;
    text-align: center;
    padding: 5px 0;
    color: #777;
}

.calendar-body .calendar-day {
    width: 14.2857%;
    border: 1px solid #EFEFEF;
    float: left;
    text-align: left;
    padding: 2px 5px;
    height: 100px;
    position: relative;
}

.calendar-body .calendar-day:hover>.calendar-alert {
    transform: scale(1)
}

.calendar-day .calendar-alert {
    width: 100%;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 100%;
    background: #fff;
    z-index: 11;
    left: 0;
    text-align: center;
    transform: scale(0);
    transition: transform 0.2s;
    border-radius: 3px;
}

.calendar-day .calendar-alert::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -10px;
    border: 5px solid transparent;
    border-top: 5px solid #fff;
    left: 50%;
    margin-left: -5px;
}

.calendar-day span {
    font-size: 12px;
    color: rgb(102, 102, 102);
}

.calendar-day:last-child {
    border-right: 0;
}

.calendar-block .calendar-body {
    width: 100%;
}

.calendar-body .calendar-success {
    background: #D4F1DF;
    border: 1px solid rgb(157, 241, 190);
    cursor: pointer;
}

.calendar-body .calendar-alarm {
    background: #FFD6B8;
    border: 1px solid rgb(253, 149, 146);
    cursor: pointer;
}

.calendar-body .calendar-primary {
    border: 1px solid #75A4B8 !important;
    cursor: pointer;
}

.test-alarm {
    margin: 20px 0;
    padding: 10px;
    border-radius: 5px;
    background: rgb(255, 179, 108);
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.test-success {
    margin: 20px 0;
    padding: 10px;
    border-radius: 5px;
    background: rgb(74, 189, 90);
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.test-alarm i,
.test-success i {
    font-size: 14px;
    color: #fff !important;
    margin-right: 5px;
}

.test-success-transition {
    transform: scale(0);
    animation: test-success-transition .2s forwards
}

@keyframes test-success-transition {
    100% {
        transform: scale(1);
    }
}

/*#EF5C5C*/

.blockTableNewTest .css-1sontr1 {
    width: 50% !important;
}

.top-task-material-btns {
    display: inline-block;
    border: 1px solid #d1ab66;
    height: 40px;
    box-shadow: none !important;
    border-radius: 2px !important;
    color: #d1ab66;
}

.top-task-material-btns button {
    width: 200px;
    text-align: center;
    height: 40px;
    color: #d1ab66;
}

.top-task-material-btns .top-task-material-btns-active {
    background: #d1ab66 !important;
    color: #fff !important;
}

.top-task-material-btns .top-task-material-btns-active:hover {
    background: #d1ab66 !important;
}

.top-task-material-btns button:hover {
    background: #fff !important;
}

.top-task-material-btnsGroup {
    width: 100%;
}

.top-task-material-btnsGroup button {
    width: 25%;
    min-width: 150px;
    border-right: 1px solid #d1ab66;
}

.top-task-material-btnsGroup button:last-child {
    border-right: 0;
}

.task-page .css-10nd86i,
.task-page .css-2o5izw,
.task-page .css-15k3avv,
.task-page .css-vj8t7z,
.task-page .css-1ep9fjw {
    min-width: 100% !important;
}

.task-page .row-report {
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0px !important;
    width: 40%;
    min-width: 200px;
    position: relative;
}

.task-page .block-content {
    padding: 0 30px !important;
    padding-top: 20px;
}

.task-page .row-report:nth-child(0) {
    float: left;
}

.task-page .row-report:nth-child(1) {
    float: right;
}

.task-page .data-fix-first {
    position: absolute;
    display: inline-block;
    left: 40%;
    top: 65px;
    width: 10%;
    height: 1px;
    background: rgb(223, 223, 223);
    margin-left: 40px;
}

.btn-def {
    background: #fff;
    border: 0;
    color: #777;
    text-align: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    margin-right: 25px;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 25px;
    border-radius: 25px;
}

.fixEditBtn {
    padding: 3px 5px;
    color: #d1ab66;
    border-radius: 15px;
    border: 1px solid #d1ab66;
    cursor: pointer;
    position: absolute;
    right: 60px;
    top: 10px;
    z-index: 1;
}

.fixEditBtn:hover {
    background: #d1ab66;
    color: #fff;
}

.fixEditBtn:hover i {
    color: #fff !important;
}

.helper-pop {
    width: 400px;
}

.helper-pop .helper-span {
    color: #00B0FF
}

.helper-pop .helper-user {
    display: inline-block;
    border: 1px solid rgb(221, 221, 221);
    width: 100%;
    margin: 10px 0;
    padding: 8px;
    border-radius: 2px;
}

.helper-pop .helper-user:first-child {
    margin-top: 0 !important;
}

.btn_back_test {
    padding: 20px 0;
    border: 0;
    background: #00B0FF;
    border-radius: 30px;
    position: absolute;
    top: 50px;
    left: 50%;
    margin-left: -150px;
    color: #fff;
    width: 300px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: box-shadow .2s;
}

.btn_back_test:hover {
    box-shadow: none
}

.min-block-right-inf .hr {
    margin: 10px 0;
    clear: both;
    background: rgb(222, 225, 230);
}

.disk-space-main {
    width: 100%;
    padding: 25px 0;
    position: relative;
}

.disk-space-main .disk-space-menu-main {
    padding: 10px;
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    border-bottom: 1px solid #adb5bd;
}

.disk-space-menu-main .disk-space-menu {
    height: 40px;
    float: left;
    line-height: 40px;
    font-weight: bold;
    padding: 0 15px;
    cursor: pointer;
}

.disk-space-menu-main .disk-space-menu-search {
    height: 40px;
    float: left;
    line-height: 40px;
    font-weight: bold;
    padding: 0 15px;
    position: relative;
}

.disk-space-menu-main .disk-space-menu:hover {
    border-bottom: 2px solid #00B0FF;
}

.i-white {
    margin-right: 10px;
    color: #fff !important;
}

.disk-space-menu-main .disk-space-menu-active {
    border-bottom: 2px solid #00B0FF;
    color: #00B0FF;
}

.disk-space-view {
    width: 100%;
    height: auto;
    overflow-y: auto;
}

.disk-space-view .disk-space-file-block {
    width: 10%;
    height: 120px;
    text-align: center;
    float: left;
    margin-top: 20px;
    padding: 0 10px;
    position: relative;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.disk-space-view .disk-space-file-block img {
    max-width: 90%;
    max-height: 90px;
}

.disk-space-view .disk-space-file-block .disk-space-file-cover {
    width: 90%;
    margin: auto;
    text-align: center;
}

.disk-space-file-cover i {
    font-size: 32px;
    color: #adb5bd !important
}

.disk-space-main .disk-space-no-file {
    width: 100%;
    padding: 50px 10px;
    color: #adb5bd;
    font-size: 24px;
    text-align: center
}

.disk-space-file-block b {
    cursor: pointer;
}

.disk-space-menu-search input {
    height: 30px;
    padding-right: 30px;
    border-radius: 15px;
    color: #6d7379 !important;
}

.disk-space-menu-search i {
    position: absolute;
    color: #adb5bd !important;
    right: 22.5px;
    top: 13px;
}

.disk-space-file-check-active {
    background: #808080b4;
    border-radius: 10px;
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, .2);*/
    color: #fff !important;
}

.disk-space-file-check-active i {
    color: #fff !important;
}

.disk-space-name-file {
    position: absolute;
    display: inline-block;
    width: 100%;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 10px;
}

.disk-space-file-block i {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -20px;
}

.disk-space-footer-check {
    height: 0;
    width: 100%;
    box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    animation: disk-space-footer-check .3s forwards;
}

@keyframes disk-space-footer-check {
    100% {
        height: 70px;
    }
}

.disk-space-footer-check-full {
    animation: disk-space-footer-check-full .3s forwards;
}

@keyframes disk-space-footer-check-full {
    100% {
        height: calc(100vh - 64px);
    }
}

.disk-space-footer-check i {
    font-size: 32px;
    color: #adb5bd !important;
}

.disk-space-footer-check .disk-space-footer-check-close {
    float: right;
    height: 70px;
    width: 50px;
    line-height: 70px;
    text-align: center;
}

.disk-space-footer-check i:hover {
    color: #6d7379 !important;
}

.disk-space-footer-check-btn {
    height: 70px;
    float: left;
}

.disk-space-footer-check-btn button {
    height: 40px;
    margin: 15px 15px;
    background: #d1ab66;
    color: #fff;
    border: 0;
    border-radius: 25px;
    float: left;
    padding: 0 20px;
    cursor: pointer;
}

.disk-space-footer-preview {
    width: 100%;
    height: calc(100vh - 134px);
    background: #6d7379;
    overflow-y: auto;
}

.disk-space-footer-check-full .disk-space-footer-preview i {
    position: absolute;
    bottom: 25px;
    right: 25px;
    font-size: 32px;
    color: #c3c9d3 !important;
}

.disk-space-footer-preview i:hover {
    color: #dae1ec !important;
}

.disk-space-footer-preview-file {
    /* max-width: 400px;
    max-height: 400px; */
    width: auto;
    height: auto;
    margin: auto;
    position: relative;
    top: 50px;
    /* background: #fff; */
    text-align: center;
    padding-bottom: 25px;
}

.disk-space-footer-preview-file img {
    max-width: 400px;
    max-height: 400px;
    border-radius: 5px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .4)
}

.custom-wysiwyg-silver {
    font-size: 32px;
    margin-left: 10px;
    color: #6d7379 !important;
}

.custom-wysiwyg {
    font-size: 20px;
    margin-left: 10px;
    color: #000 !important;
    background: #fff;
}

.disk-space-footer-preview-file video {
    max-width: 400px;
    max-height: 400px;
    border-radius: 5px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .4)
}

.disk-space-footer-preview-file audio {
    width: 70%;
    max-height: 400px;
    border-radius: 5px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .4)
}

.lds-default {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    left: 50%;
    top: 100px;
    margin-left: -32px;
}

.lds-default div {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #045072;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 29px;
    left: 53px;
}

.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
}

.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
}

.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
}

.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
}

.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
}

.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
}

.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
}

.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
}

.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
}

.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 50px;
    left: 41px;
}

.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
}

@keyframes lds-default {

    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}

.addition-material-block {
    width: 40%;
    margin: 25px 0;
    border-radius: 2px;
    border: 1px solid #dddddd;
}

.addition-material-block .addition-material-header {
    background: #fafafa;
    height: 42px;
    width: 100%;
    padding: 0 10px;
    line-height: 42px;
}

.addition-material-block .addition-material-header i {
    color: #000 !important;
    font-size: 20px;
}

.addition-material-block .addition-material-edit {
    padding: 15px 10px;
    width: 100%;
    background: #fff;
}

.addition-material-block .addition-material-add {
    position: relative;
    width: 100%;
    margin: 15px auto;
}

.addition-material-block .addition-material-add video,
.addition-material-block .addition-material-add audio {
    width: 100%;
    border-radius: 5px;
}

.addition-material-block .addition-material-add img {
    max-width: 100%;
    max-height: 200px;
    border-radius: 5px;
}

.addition-material-block .addition-material-icon {
    width: 40px;
    float: left;
    line-height: 100%;
    font-size: 30px !important;
    text-align: center;
}

.addition-material-block .addition-material-icon i {
    color: #ccc !important;
    cursor: default;
}

.addition-material-block .addition-material-file {
    width: calc(100% - 40px);
    padding-left: 10px;
    border-left: 5px solid #ccc;
    float: right;
}

.help-wysiwyg {
    height: 42px;
    background: #FAFAFA;
    line-height: 42px;
    color: #777;
    padding: 0 10px;
}

.view-add-material {
    width: 450px;
    text-align: center;
}

.view-add-material img {
    max-width: 90%;
    max-height: 400px;
}

.view-add-material video,
.view-add-material audio {
    width: 90%;
}

.lesson-material-type {
    max-width: 100%;
}

.lesson-material-type audio {
    width: calc(100% - 10px) !important;
}

.lesson-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin: 25px 0;
}

.lesson-video-container video,
.video-content {
    min-width: 97% !important;
}

.video-content {
    max-width: 100%;
}

.lesson-page-content-container {
    display: flex;
    width: 100%;
}

.lesson-page-content-container .lesson-page-content {
    width: 70%;
    padding-right: 90px;
}

.lesson-page-content-container .content-additional-materials {
    width: 30%;
    background: rgba(238, 238, 238, 0.5);
    position: fixed;
    min-height: 100%;
    height: 100%;
    right: 0;
    overflow-y: auto;
}

.fix-table-report {
    overflow-x: auto;
}

.additional-material-wrapper {
    padding-left: 10px;
}

.additional-material-fixed {
    position: relative;
    padding-bottom: 100px;
}

.additional-material-fixed h4 {
    padding-left: 15px;
}

.audio-content {
    outline: none;
}

.content-additional-materials-header {
    width: 100%;
    height: 80px;
    background-color: #2f2f2f;
    margin: 0;
    line-height: 80px;
    position: relative;
}

.content-additional-materials-header h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    padding: 0 20px;
    margin: 0;
    display: inline;
}

.content-additional-materials-count {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    right: 5px;
    top: 5px;
    font-size: 14px;
    color: #fff;
    border: 1px solid #d1ab66;
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    cursor: default;
}

.additional-material-header {
    display: flex;
    align-items: center;
}

.additional-material-header .additional-material-icon {
    margin-right: 10px;
    cursor: default;
    font-size: 26px;
}

.additional-material-wrapper .lesson-material-type {
    margin-bottom: 12px;
}

.additional-material-wrapper .lesson-video-container {
    margin-bottom: 12px;
    /* padding-bottom: 25px; */
}

.lesson-video-container video,
.additional-materials-container img {
    max-width: 31.333333% !important;
    margin: 1%;
}

.content-additional-materials-header-cont {
    position: relative;
}

button {
    outline: 0;
}

.play-type-controle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #00B0FF;
    text-align: center;
    line-height: 50px;
    font-size: 25px;
    position: fixed;
    bottom: 25px;
    right: 30%;
    margin-right: 25px;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1)
}

.play-type-controle i {
    position: relative;
}

@media all and (max-width: 1660px) {
    .task-page {
        width: 70% !important;
    }
}

@media all and (max-width: 1660px) {
    .adaptive-fix-test {
        width: 70% !important;
    }
}

@media all and (max-width: 1024px) {
    .mobile-progress {
        width: 100%;
        text-align: center;
        margin: 25px auto;
    }

    .mui-fixed h6 {
        font-size: 15px !important;
    }

    /* img{
        pointer-events: none
    } */
    body {
        background: #fff !important;
        color: rgb(78, 78, 78)
    }

    .user-header-block:hover .user-header-pop {
        display: inline-block;
    }

    .video-mobile-test {
        max-width: 100% !important;
        min-width: 100% !important;
    }

    .mobile-block-content {
        padding: 20px 5px !important;
    }

    .mobile-pdf {
        position: fixed;
        overflow: auto;
        background: rgb(78, 78, 78) !important;
        width: 100%;
        height: 100vh;
        top: 64px;
        left: 0;
        z-index: 1;
        text-align: center;
    }

    .image-fix-scale {
        margin-top: calc(100% + 50px);
        /* transform: translate(-50%, -50%) !important; */
        /* inset: 20% 50% 50% 10%; */
    }

    .mobile-pdf .react-pdf__Document {
        margin-top: 70px;
    }

    .mobile-pdf p {
        color: rgb(27, 27, 27);
        font-size: 14px;
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    .mobile-pdf .react-pdf__Page__canvas {
        margin-top: 25px;
    }

    .mobile-pdf img {
        margin-top: 25px;
        border-radius: 5px;
        box-shadow: 0 10px 25px rgba(0, 0, 0, .4);
    }

    .mobile-pdf video,
    .mobile-pdf audio {
        margin-top: 25px;
        max-width: 100%;
        border-radius: 5px;
        box-shadow: 0 10px 25px rgba(0, 0, 0, .4);
    }

    .mobile-pdf audio {
        width: 99%
    }

    .mobile-pdf .mobile-pdf-nav {
        width: 100%;
        height: 40px;
        background: rgb(34, 34, 34);
        border-bottom: 1px solid rgb(15, 15, 15);
        padding: 5px 10px;
        position: fixed;
        left: 0;
        top: 55px !important;
        text-align: center;
        line-height: 32px;
        color: rgb(146, 146, 146);
        z-index: 99999;
    }

    .mobile-pdf .mobile-pdf-nav button {
        width: 30px;
        height: 30px;
        background: transparent;
        border-radius: 50%;
        text-align: center;
        padding: 0;
        margin-right: 10px;
        border: 0;
        z-index: 99999;
    }

    .mobile-pdf .mobile-pdf-nav i {
        color: rgb(146, 146, 146);
        font-size: 20px;
    }
}

.mobile-pdf {
    padding-top: 50px;
    position: fixed;
    overflow: auto;
    background: rgb(78, 78, 78) !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
}

.mobile-pdf p {
    color: rgb(27, 27, 27);
    font-size: 14px;
    width: 100%;
    display: inline-block;
    text-align: center;
}

.mobile-pdf .react-pdf__Page__canvas {
    margin-top: 25px;
}

.mobile-pdf img {
    margin-top: 25px;
    border-radius: 5px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .4);
}

.mobile-pdf video,
.mobile-pdf audio {
    margin-top: 25px;
    max-width: 90%;
    border-radius: 5px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .4);
    max-height: 95%;
}

.mobile-pdf audio {
    width: 99%
}

.mobile-pdf .mobile-pdf-nav {
    width: 100%;
    height: 40px;
    background: rgb(34, 34, 34);
    border-bottom: 1px solid rgb(15, 15, 15);
    padding: 5px 10px;
    position: fixed;
    left: 0;
    top: 64px;
    text-align: center;
    line-height: 32px;
    color: rgb(146, 146, 146);
}

.mobile-pdf .mobile-pdf-nav button {
    width: 30px;
    height: 30px;
    background: transparent;
    border-radius: 50%;
    text-align: center;
    padding: 0;
    margin-right: 10px;
    border: 0;
}

.mobile-pdf .mobile-pdf-nav i {
    color: rgb(146, 146, 146);
    font-size: 20px;
}

/*JQX FRAMEWORK*/
.jqx-group-button-normal {
    color: #00B0FF !important;
    background: #fff !important;
    border: 1px solid #00B0FF !important;
}

.jqx-fill-state-pressed,
.jqx-fill-state-pressed-material {
    color: #fff !important;
    background: #00B0FF !important;
}

.jqx-widget-content {
    border-color: rgb(243, 243, 243) !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05) !important;
}

.jqx-scheduler-legend-bar-bottom {
    background: #fff !important;
}

.jqx-scrollbar-state-normal {
    background: #fff !important;
}

.jqx-scrollbar-thumb-state-normal-material {
    border-color: #00B0FF !important;
    background: #00B0FF !important;
}

.jqx-scheduler-month-weekend-cell {
    background: #EFF8FF !important;
}

.jqx-widget .jqx-grid-cell,
.jqx-widget .jqx-grid-column-header,
.jqx-widget .jqx-grid-group-cell {
    border-color: #E0E9F0 !important;
}

.jqx-widget-header {
    border-color: rgb(224, 224, 224) !important;
}

.connection-icon {
    font-size: 16px !important;
    cursor: default;
}

.connection-icon.connect {
    color: #d1ab66;
}

.connection-icon.disconnect {
    color: red;
}

.chat-top-wrapper {
    display: flex;
}

.chat-dialogues {
    flex-basis: 25%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 14px;
}

.chat-dialogues-container .chat-dialogues-contact-list {
    color: #858585;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin: 14px 0;
}

.chat-dialogues-container .dialog .chat-dialogues-contact-right {
    padding: 4px 0 4px 6px;
    flex-grow: 2;
    overflow: hidden;
}

.chat-dialogues-container .dialog {
    margin: 10px;
    cursor: pointer;
    position: relative;
    height: 52px;
    display: flex;
    padding: 2.5px;
}

.chat-dialogues-container .dialog.active {
    background-color: transparent;
    border-radius: 16px;
    animation: dialog-active .2s forwards;
}

@keyframes dialog-active {
    100% {
        box-shadow: 0px 3px 8px -5px rgba(0, 0, 0, .45);
        background-color: rgba(0, 0, 0, 0.03);
    }
}

.chat-messages-container {
    flex-basis: 75%;
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.chat-controls-container {
    width: 100%;
    flex-shrink: 1;
}

.chat-controls-container .hr {
    margin: 10px 0;
    background: #ccc;
}

.chat-controls-container .chat-controls-message {
    height: 80px;
    position: relative;
    padding: 10px;
    width: 100%;
    resize: none;
    margin-top: 1px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding-right: 30px;
}

.chat-dialogues-controls .css-1hwfws3 {
    padding-left: 23px;
}

.clear-search-chat {
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 11;
    color: #CCCCCC
}

.clear-search-chat:hover {
    color: #777
}

.chat-controls-container .chat-controls-submit-wrap {
    width: 100%;
    display: flex;
}

.chat-controls-container .chat-controls-submit {
    margin: 10px 10px 10px auto;
    outline: none;
    transition: background-color .3s ease;
}

.chat-messages {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    padding: 0 70px 10px;
    overflow-x: hidden;
}

.chat-messages .message {
    margin: 10px 0;
    max-width: 70%;
    min-height: 58px;
    background-color: rgba(4, 80, 114, 0.1);
    display: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    position: relative;
    padding: 10px;
}

.message-file-link-preview-pop {
    max-width: 100%;
    max-height: 450px
}

.chat-messages .message.mine {
    margin-left: auto;
    background-color: rgba(4, 80, 114, 0.6);
    flex-direction: row-reverse;
    justify-content: space-around;
}

.chat-messages .message.file {
    background-color: #fff;
}

.chat-messages .message .message-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #000;
    margin: 8px 15px auto auto;
    overflow-wrap: break-word;
}

.message-file {
    display: flex;
    flex-wrap: wrap;
}

.message-file-link {
    line-height: 32px;
}

.message-file-icon {
    font-size: 32px;
    margin-right: 10px;
}

.message-file-download-icon {
    font-size: 18px;
    margin-left: 10px;
}

.chat-messages .message .message-name {
    position: absolute;
    top: 4px;
    font-size: 12px;
    color: #000;
    font-weight: bold;
    white-space: nowrap;
}

.chat-messages .message-name {
    padding: 4px;
}

.chat-messages .message.mine .message-name {
    right: 4px;
    color: #fff;
}

.chat-messages .message.opponent .message-name {
    left: 4px;
}

.chat-messages .message.mine .message-text {
    margin: 8px auto auto 15px;
    padding: 10px;
    color: #fff;
}

.chat-dialogues-new-message {
    position: absolute;
    background-color: #FF7410;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    cursor: default;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.chat-dialogues-online-block {
    display: flex;
    padding: 4px 0 4px 4px;
}

.chat-dialogues-online-block.offline .chat-dialogues-online-icon {
    background-color: #D9D9D9;
}

.chat-dialogues-online-block .chat-dialogues-online-status {
    font-size: 12px;
    color: #B5B5B5;
    margin-left: 6px;
}

.chat-dialogues-online-block .chat-dialogues-online-icon {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: block;
    background-color: #38D39F;
}

.silver-icon {
    color: #808080;
    cursor: default;
}

.chat-messages-header {
    border-bottom: 1px solid #ccc;
    height: 80px;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    align-items: center;
}

.chat-messages-header .default-header-avatar {
    width: 60px;
    height: 60px;
    margin: 0;
}

.chat-dialogues-container .default-header-avatar {
    width: 44px;
    height: 44px;
    margin: 1px 4px 0 4px;
    flex-shrink: 0;
}

.chat-messages-header .chat-messages-wrapper {
    padding: 6px 25px;
    display: flex;
    flex-direction: column;
}

.chat-messages-wrapper .chat-messages-header-username {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #505050;
    margin: 0 0 2px 0;
}

.chat-messages-header .chat-messages-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.chat-dialogues-avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-right: 4px;
    flex-shrink: 0;
}

.chat-dialogues-dialog-name {
    font-size: 13px;
    white-space: nowrap;
}

.chat-messages .message .chat-messages-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    margin: 0;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
}

.chat-messages .message.opponent .chat-messages-avatar {
    left: -60px;
}

.chat-messages .message.mine .chat-messages-avatar {
    right: -60px;
}

.chat-empty-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
}

.chat-empty-dialog h4 {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    color: #959595;
    margin: 0;
    text-align: center;
}

.message-file-link-preview {
    max-width: 100%;
    max-height: 320px;
}

.menu-total-messages-wrap {
    position: relative;
}

.left-content-menu-total-messages {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #FF7410;
    display: block !important;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    overflow: hidden;
}

/*
    MOBILE
*/
.mobile {
    width: 100%;
    height: 100%;
    /* position: fixed; */
    overflow-y: auto;
    /* background: #2f2f2f; */
}

.mobile-start-page {
    background: #2f2f2f;
    height: 100%;
    color: #fff;
}

.mobile-start-page .mobile-home-title {
    /* font-weight: bold; */
    color: #fff;
    font-size: 16px;
    width: 100%;
    margin: 25px 0;
    text-align: center;
}

.mobile-start-page .mobile-logo-ziko {
    width: 100%;
    height: 94px;
    margin-top: 25px;
    background: url(../../../img/logo.png) no-repeat center center;
    background-size: auto 94px;
}

.mobile-start-page .mobile-home-app-container {
    width: 50%;
    text-align: center;
    float: left;
    margin-top: 20%;
}

.mobile-home-app-container i {
    color: rgb(243, 243, 243);
    font-size: 67px;
}

.mobile-start-page .mobile-coming-soon,
.mobile-start-page .mobile-coming-soon i {
    color: rgba(255, 255, 255, 0.5) !important;
}

.mobile-html-content {
    width: 100%;
    word-wrap: break-word;
    padding-bottom: 55px;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.mobile-html-content img {
    max-width: 100%;
    border-radius: 2px;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, .35); */
}

.mobile-html-content video {
    width: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 3px;
    object-fit: inherit;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .35);
}

.font-italic {
    font-style: italic;
    /* color: rgb(243, 243, 243) */
}

.mobile .mobile-addition-materials {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #2f2f2f;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    padding: 0 15px;
    color: #fff;
}

.mobile .mobile-addition-materials i,
.color-white,
.mobile-addition-materials-modal i {
    color: #fff !important;
    margin-right: 10px;
}

.mobile .mobile-addition-materials-num {
    margin-left: 10px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    float: right;
    margin-top: 10px;
    /* display: inline-block; */
}

.mobile-addition-materials-modal {
    color: #fff;
    padding: 15px;
    width: 100%;
    position: fixed;
    top: 55px;
    height: 100%;
    min-height: 100%;
    padding-bottom: 100px;
    left: 0;
    text-align: left;
}

.mobile-addition-materials-modal .hr {
    background: rgba(0, 0, 0, .2) !important;
    margin: 10px 0;
}

.align-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

video {
    object-fit: inherit;
}

.mobile-pdf-uri {
    width: 90%;
    margin: 10px auto;
    text-align: left;
    padding: 10px 10px;
    color: #fff;
    background: #2f2f2f;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .35);
    border-radius: 5px;
}

.mobile-addition-materials-card {
    width: 100%;
    min-height: 80px;
    background: #7b7b7b;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .35);
    margin: 15px auto;
    color: #fff;
    position: relative;
    line-height: 80px;
    padding: 0 10px;
    padding-left: 80px;
    text-align: left;
}

.mobile-addition-materials-card span {
    display: inline-block;
    vertical-align: middle;
    line-height: 25px;
}

.mobile-addition-materials-card img {
    position: absolute;
    max-height: 50px;
    max-width: 70px;
    left: 0px;
    top: -10px !important;
    box-shadow: none;
    /* bottom: 0 !important; */
}

.mobile-current-file {
    text-align: center;
}

.mobile-current-file .btnSaveProfile {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    float: normal;
    z-index: 999999999999999;
    background: #2f2f2f;
    font-size: 18px;
    padding-right: 1px;
    padding-top: 1px;
}

.disabled-block-education * {
    color: #777 !important;
    background: rgb(238, 238, 238);
    filter: grayscale(100%);
}

/*
CKeditor
*/
.ck .ck-link-form,
.ck .ck-balloon-rotator {
    background: #2F2F2F !important;
}

.ck .ck-link-form input,
.ck .ck-balloon-rotator .ck-button__label {
    background: #fff !important;
}

.ck .ck-balloon-rotator .ck-button__label {
    border-radius: 2px;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
    display: inline-block !important;
}

.ck.ck-balloon-panel {
    position: absolute !important;
}

.ck.ck-toolbar__separator {
    background: rgba(255, 255, 255, 0.2);
}

.ck.ck-editor {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-radius: 5px 5px 0 0 !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border: 1px solid rgb(233, 233, 233) !important;
}

.ck.ck-dropdown__panel,
.ck.ck-toolbar {
    border: 1px solid #2f2f2f !important;
}

.ck-rounded-corners .ck.ck-button,
.ck-rounded-corners a.ck.ck-button,
.ck.ck-button.ck-rounded-corners,
a.ck.ck-button.ck-rounded-corners {
    background: rgba(240, 245, 251, .1);
    color: #fff;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    background: #2f2f2f;
}

.ck.ck-list,
.ck.ck-toolbar {
    background: #2f2f2f !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
    background: rgba(240, 245, 251, .2) !important;
    border-bottom: 2px solid #fdd385;
}

.ck.ck-button:active,
.ck.ck-button:focus,
a.ck.ck-button:active,
a.ck.ck-button:focus {
    background: rgba(240, 245, 251, .2) !important;
    border-bottom: 2px solid #fdd385;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
    background: rgba(240, 245, 251, .2) !important;
}

.ck.ck-button.ck-on:not(.ck-disabled):active,
a.ck.ck-button.ck-on:not(.ck-disabled):active {
    background: rgba(240, 245, 251, .3) !important;
    border-bottom: 2px solid #fdd385;
    box-shadow: none !important;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    background: #2f2f2f;
}

.ck .ck-labeled-input__status,
.ck .ck-insert-table-dropdown__label {
    color: #fff !important;
}

/* .ck-content .image-style-align-left, .ck-content .image-style-align-right{
    clear: none !important;
} */
.simple-box {
    padding: 10px;
    margin: 1em 0;

    /* background: rgba( 0, 0, 0, 0.03 ); */
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    display: flex;
    width: 100%;
    flex-direction: row;
}

.simple-box-title {
    display: none;
}

.simple-box-title,
.simple-box-description {
    padding: 10px;
    margin: 0;
    margin: 0 10px;
    background: #FFF;
    /* //border: solid 1px hsl(0, 0%, 77%); */
    flex-basis: 100%;
    border: dotted 3px rgba(0, 0, 0, 0.1) !important;
    outline: none;
    transition: border .2s;
}

.simple-box .simple-box-description:focus {
    border: dotted 3px #47A4F5 !important;
    /* outline-color: #47A4F5 !important;
    outline-style: solid !important;
    outline-offset: 0px !important;
    outline-width: 1px !important; */
}

/* .simple-box-title {
    margin-bottom: 10px;
} */
.ck .ck-content .table {
    width: auto !important;
    box-shadow: none !important;
}

.ck.ck-button,
a.ck.ck-button {
    border: 2px solid transparent !important;
}

.ck-emoji {
    max-width: 280px;
    text-align: center !important;
}

.ck-emoji__actions .ck-button {
    float: left !important;
    margin: auto !important;
}

.simple-box-description img {
    max-width: 100%;
}

.lesson-page-content .simple-box-description,
.lesson-page-content .simple-box {
    border: 0 !important;
}

.lesson-page-content .image figcaption {
    background: rgba(0, 0, 0, 0.05);
    padding: 5px;
    text-align: center;
}

.lesson-page-content .image {
    text-align: center;
}

.lesson-page-content .image img {
    max-width: 100%;
}

.lesson-page-content .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
}

.lesson-page-content .table {
    box-shadow: none;
    border: 0;
}

.lesson-page-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px double #b3b3b3;
    margin: auto;
}

.lesson-page-content .image-style-align-right {
    float: right;
}

.lesson-page-content .image-style-align-left {
    float: left;
}

.lesson-page-content .table table th,
.lesson-page-content .table table td,
.lesson-page-content .table table tr {
    min-width: 2em;
    padding: .4em;
    border: 1px solid #ccc;
}

.mention {
    background: rgb(189, 225, 255);
    color: rgb(24, 101, 218) !important;
    border-radius: 2px;
    padding: 1px 3px;
    font-size: bolder;
    cursor: pointer;
}

.ck a {
    color: rgb(46, 130, 255) !important;
}

.lesson-page-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
}

@media all and (max-width: 520px) {
    .simple-box {
        flex-direction: column;
    }

    .simple-box-description img {
        max-width: 100%;
    }

    .simple-box-description,
    .simple-box {
        border: 0 !important;
    }

    .image figcaption {
        background: rgba(0, 0, 0, 0.05);
        padding: 5px;
        text-align: center;
    }

    .image {
        text-align: center;
    }

    .media {
        clear: both;
        margin: 1em 0;
        display: block;
        min-width: 15em;
    }

    .table {
        box-shadow: none;
        border: 0;
        overflow-x: auto;
        width: auto;
    }

    .table table {
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px double #b3b3b3;
        margin: auto;
    }

    .table table th,
    .table table td,
    .table table tr {
        min-width: 2em;
        padding: .4em;
        border: 1px solid #ccc;
    }

    .mention {
        background: rgb(189, 225, 255);
        color: rgb(24, 101, 218) !important;
        border-radius: 2px;
        padding: 1px 3px;
        font-size: bolder;
    }

    .ck a {
        color: rgb(46, 130, 255) !important;
    }

    blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        font-style: italic;
        border-left: 5px solid #ccc;
    }
}

.block-touch {
    position: absolute;
    /* width: 400px; 
    height: 400px; */
    top: 0;
    left: 0;
    background: transparent;
    /* margin-top: -100px; */
    /* margin-left: -200px; */
    /* background: #ccc url(../../../img/edfuc1.jpg) no-repeat center center; */
    background-size: 100%;
    /* transform: translate(50, 50) */
}

.plateContainer {
    position: absolute;
    left: 0;
    top: 0;
    background: red;
    /* background: #777; */
    -ms-touch-action: none;
    ms-touch-action: none;
    touch-action: none;

}

.plate {
    position: absolute;
    left: -200px;
    top: -200px;
    background: #ccc;
}

.fix-tabs-overflow,
.fix-tabs-overflow div[data-swipeable="true"] {
    overflow: visible !important;
    overflow-y: visible !important;
    overflow-x: hidden !important;
}

.lesson-page-content img {
    max-width: 100%;
}

.fix-image-web {
    cursor: pointer;
    max-width: 100%;
}

.search-user-input {
    width: 300px;
}

.file-viewer-desc img {
    margin-top: 100px;
}

.icon-read {
    color: #66D6B0 !important;
}

.icon-read-disable {
    color: #ccc !important;
}

.fix-selector-width {
    width: calc(100% - 50px);
    float: left;
}

.fix-del-after-icon {
    font-size: 20px;
    color: rgb(236, 135, 135) !important;
    position: relative;
    top: 18px;
    float: right;
}

.fix-del-after-icon:hover {
    color: rgb(238, 79, 79) !important;
}

.mb-0 {
    margin-bottom: 5px !important;
}

/*
user profile
*/
.user-profile-left {
    width: 400px;
    float: left;
    padding-right: 15px;
}

.user-profile-right {
    width: calc(100% - 400px);
    float: right;
    padding-left: 15px;
}

.user-profile-container {
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    width: 100%;
    padding: 25px;
}

.user-profile-avatar {
    border-radius: 50%;
    margin-bottom: 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    max-width: 120px;
}

.user-profile-role {
    margin-top: 25px;
    border-radius: 3px;
    width: 100%;
    background: rgb(239, 242, 243);
    padding: 8px 0;
    color: rgb(115, 118, 119);
}

.user-profile-l-r {
    width: 100%;
}

.user-profile-l-r div:first-child {
    text-align: left;
    float: left;
    width: 50%;
    color: rgb(168, 177, 180);
}

.user-profile-l-r div:nth-child(2) {
    text-align: right;
    float: left;
    width: 50%;
}

.user-profile-l-r i {
    color: rgb(168, 177, 180) !important;
    margin-right: 10px;
}

.user-profile-award {
    width: 100%;
    /* display: flex; */
    /* flex-wrap: wrap;
    justify-content: space-between; */
}

.user-profile-award img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    /* background: url(../../../img/ava.jpg) no-repeat center center;
    background-size: 100%; */
    margin-right: 15px;
    margin-top: 15px;
}

.user-profile-award img:nth-child(5n+5) {
    margin-right: 0;
}

.user-profile-status {
    background: rgb(117, 212, 117);
    padding: 3px 8px;
    color: #fff;
    border-radius: 4px;
}

.user-profile-container .jqx-widget-content {
    box-shadow: none !important;
}

.user-profile-btn {
    margin-top: 25px;
    width: 100%;
    padding: 8px 0;
    text-align: center;
    background: #000;
    background: #D1AB66;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.user-profile-task-block {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 25px;
}

.user-profile-task-block:last-child {
    margin-bottom: 0;
}

.user-profile-task-block span,
.user-profile-task-block i {
    color: rgb(168, 177, 180) !important;

}

.mgr-10 {
    margin: 10px;
}

.user-profile-task-details {
    width: calc(100% - 10px);
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #ccc;

}

.mobile-html-content {
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
}

.loadMaterialDual {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
}

.loadMaterialDual div {
    max-width: 45%;
}

.dialog_autogen_questions {
    padding: 10px;
    min-width: 400px;
}

.ReactTable .rt-td,
.number {
    white-space: nowrap !important;
    /* Отменяем перенос текста */
    overflow: hidden !important;
    /* Обрезаем содержимое */
    text-overflow: ellipsis !important;
    /* Многоточие */
}